<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 使用方法
 * @Date: 2020-11-11 19:18:37
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-11-17 10:55:39
 * @FilePath: /yue_quanzhan_h5_new/src/views/localLife/comeOn/UsageMethod.vue
-->
<template>
  <div class="content">
    <div v-for="(item,index) in data" :key="index" class="usage_text">
      <p>{{ item.title }}</p>
      <p v-for="(items,indexs) in item.content" :key="indexs" class="p_t">{{ items }}</p>
    </div>
    <div class="usage_btn" @click="phone('4001109600')"><img src="@/assets/images/kf.png" alt=""> 联系客服</div>
  </div>
</template>

<script>
import { getXJHelpInfo } from '@/services/comeOn.js'
import { sharePage } from '@/services/activity'

export default {
  data() {
    return {
      pid: '',
      data: ''
    }
  },
  created() {
    const _self = this
    _self.pid = this.$route.query.id
    _self.configShare()
    this.getHelpInfo()
  },
  methods: {
    phone(tel) {
      window.location.href = 'tel:' + tel
    },
    getHelpInfo() {
      getXJHelpInfo({ id: this.pid }).then(res => {
        if (Number(res.code) === 200) {
          this.data = res.data
        }
      })
    },
    // 分享
    configShare() {
      // const that = this
      // const ua = navigator.userAgent.toLowerCase()// 获取设备信息
      const title = '全国10000+加油站接入，下单立减，边省边赚，让你到爱车“吃饱饭"'
      const desc = '我在悦淘，绝对适合你，速来围观～'
      const link = 'https://web.yuetao.vip/refueling-list'
      const img = 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-16/19/yuelvhuiOHhTarxh8s1605525102.png'
      const ajaxUrl = 'https://gateway.yuetao.vip/center/v1/app/WechatShareH5?share_url=' + encodeURIComponent(window.location.href)
      sharePage(ajaxUrl).then(res => {
        const { data } = res
        // eslint-disable-next-line no-undef
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.app_id, // 必填，企业号的唯一标识，此处填写企业号corpid
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.noncestr, // 必填，生成签名的随机串
          signature: data.signature, // 必填，签名，见附录1
          jsApiList: [
            'onMenuShareTimeline',
            'onMenuShareAppMessage'
          ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        })
        // eslint-disable-next-line no-undef
        wx.ready(() => {
          // eslint-disable-next-line no-undef
          wx.onMenuShareTimeline({
            // 分享到朋友圈
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: img // 分享图标
          })
          // eslint-disable-next-line no-undef
          wx.onMenuShareAppMessage({
            // 分享给朋友
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: img // 分享图标
          })
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  background-color: #f8f8f8;
  min-height: 100vh;
  padding-top: 10px;

  .usage_text {
    background-color: #ffffff;
    margin: 0 10px;
    padding: 10px;
    border-radius: 5px;

    & > p {
      text-align: left;
      line-height: 20px;
    }

    & > p:nth-child(1) {
      color: #333333;
      font-size: 14px;
    }

    .p_t {
      color: #666666;
      font-size: 13px;
      text-indent: 2em;
    }
  }

  .usage_btn {
    background-color: #FFFFFF;
    margin-top: 10px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 15px;
      height: 15px;
      margin: 5px;
    }
  }
}
</style>
